@import '../../../styles/variables.scss';

.desktop {
  margin-top: 50px;
  padding: 0 30px;
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
  }
}

/******************* MOBILE NAV ************************/

.mobile {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
   
  }

  .logo {
    width: 162px;
    height: 18px;
  }
}
.mobileNavWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 70px;
  flex-grow: 1;
  padding: 0 15px;

}


.logo {
  width: 162px;
  height: 18px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $breakpoint-md) {
    margin-left: unset;
    margin-right: auto;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .desktop {
    padding-right: 100px;
  }
  .logo {
    margin-right: unset;
    margin-left: 120px;
  }
}
