@import '../../../styles/variables';

.apply-panel {
  padding: 40px 20px;
  max-width: 800px;
  // height: calc(100vh - 80px);
  margin: 0 auto;
  .apply-joystick {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 36px;
    line-height: 30px;
    text-align: center;
  }
  @media screen and (max-width: $breakpoint-md) {
    .apply-joystick {
      font-size: 24px;
    }
    height: calc(100vh - 372px);
  }
  .shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .text-teal-900 {
    color: rgb(19 78 74);
  }
  .py-3 {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .bg-teal-100 {
    background-color: rgb(204 251 241);
  }
  .border-teal-500 {
    border-color: rgb(20 184 166);
  }
  .border-t-4 {
    border-top: 4px solid;
  }
  .rounded-b {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .flex {
    display: flex;
  }
  .py-1 {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }
  .text-teal-500 {
    color: rgb(20 184 166);
  }
  .fill-current {
    fill: currentColor;
  }
  .w-6 {
    width: 1.5rem;
  }

  .h-6 {
    height: 1.5rem;
  }
  .mr-4 {
    margin-right: 1rem;
  }
  .font-bold {
    font-weight: 700;
  }
}
#none-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: $breakpoint-md) {
    padding-top: 0;
    height: calc(100vh - 130px);
  }
  
  #iframe-wrapper {
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    iframe {
      width: 100%;
      height: 100vh;
      @media screen and (max-width: $breakpoint-md) {
        height: calc(100vh - 130px);
      }
    }
    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
      height: calc(100vh - 130px);
      margin: 0 auto;
    }
  }
}
