@import '../../../styles/variables.scss';

.socialIcons {
  display: flex;
  gap: 16px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
