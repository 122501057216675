@import './variables';

:root {
  --toastify-toast-width: 330px;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body > #root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden auto;
}

body {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #02091b;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: #233c7a;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
}

.__react_component_tooltip {
  background: #328946 !important;
  border-radius: 90px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;

  &::after {
    border-top-color: #328946 !important;
  }
}

.Toastify__toast-container--top-right {
  top: 6em;
}

.Toastify__toast {
  border-radius: 20px;
  font-family: 'Poppins';
  font-size: 19px;
  font-weight: 500;
  padding: 17px 20px;
  position: relative;
  overflow: visible;
  color: white;

  p {
    margin: 0;
  }

  &.Toastify__toast--success {
    background: $green-500;
  }

  &.Toastify__toast--error {
    background: $red-400;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;

    .Toastify__toast-icon {
      width: 44px;
      height: 44px;
    }

    .Toastify__toast-submsg {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .Toastify__close-button {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
    background: #fff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    opacity: 1;
    color: #000;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
