@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Courier';
  font-weight: 400;
  src: url('../assets/fonts/CourierPrime-Regular.ttf') format('truetype');
}
