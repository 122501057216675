@import '../../../styles/variables';

.applyPanel {
  width: 370px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
}

.applyJoystick {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 90px;
  text-align: center;
  color: $white;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  height: 60px;
  box-sizing: border-box;
  border-radius: 12px;

  &:hover {
    opacity: 0.9;
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-left: 18px;
  }
  .buttonText {
    margin-right: auto;
    margin-left: auto;
  }
}

.primary {
  background: $green-400;
  border: 1px solid $green-400;
  color: $white;
}
.secondary {
  background: $white;
  border: 1px solid $iron;
  color: $black-perl;
  margin-top: 15px;
}

// joy token prohibition popup
.prohibitionPopUp {
  position: relative;
  text-align: center;
  background: $white;
  border-radius: 16px;
  padding: 50px 0 30px 0;
}
.tokenNotFoundText {
  max-width: 330px;
  margin: 30px auto;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: $black-perl;
}
.tokenMessage {
  max-width: 330px;
  margin: 17px auto;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: $black-perl;
}

.closeIcon {
  position: absolute;
  right: -18px;
  top: -15px;
  cursor: pointer;
}


.disconnect {
  display: flex;
  gap: 20px;
}