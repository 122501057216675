@import '../../styles/variables.scss';
.applyPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #02091b;
  height: 100vh;

  background-image: url('../../assets/img/apply-page-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
